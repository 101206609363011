<template>
  <div>
    <StepLayout
      :color="getActivationLoadingStep === 1 ? 'primary' : 'secondary'"
      :title="
        $t(`accountIsCreating.title${getActivationLoadingStep}`, {
          fullname: getForm.name,
        })
      "
      :description="
        $t(`accountIsCreating.description${getActivationLoadingStep}`, {
          fullname: getForm.name,
        })
      "
    >
      <h4 class="u-color-primary">
        {{ $t("accountIsCreating.listTitle") }}
      </h4>
      <ul class="c-list-select">
        <li class="c-list-select__item" v-for="list in 6" :key="list">
          <i class="icofont-check u-color-primary"></i>
          {{ $t(`accountIsCreating.list${list}`) }}
        </li>
        <li class="c-list-select__item">
          {{ $t(`accountIsCreating.listMore`) }}
        </li>
      </ul>
    </StepLayout>
  </div>
</template>

<script>
import StepLayout from "@/layouts/StepLayout";
export default {
  components: {
    StepLayout,
  },
  computed: {
    getForm() {
      return this.$store.state.form.form;
    },
    getActivationLoadingStep() {
      return this.$store.state.form.activationLoadingStep;
    },
    getFormLoading() {
      return this.$store.state.form.isLoading;
    },
  },
  mounted() {
    const name = document.getElementById("name");
    if (name) name.focus();
  },
};
</script>

<style></style>
