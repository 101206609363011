<template>
  <h1 class="s-official-logo" :class="{ mini }">
    <span class="u-color-primary">Heo</span><span>cademy</span>
  </h1>
</template>

<script>
export default {
  props: {
    mini: {
      default: false,
      type: Boolean,
    },
    withSlogan: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style></style>
