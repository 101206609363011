<template>
  <div>
    <div :class="`u-color-${color}`" class="s-get-started__steps__card__title">
      {{ title }}
    </div>
    <div class="s-get-started__steps__card__description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
    description: {
      default: "",
    },
    color: {
      default: "primary",
    },
  },
};
</script>

<style></style>
