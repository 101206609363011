<template>
  <div
    class="s-get-started__steps"
    :style="`background-image:url('/images/bg2.webp')`"
  >
    <div class="s-get-started__steps__card s-get-started__steps-enter">
      <div class="page-loading u-margin-bottom-small" v-if="getFormLoading">
        <span class="loader"></span>
      </div>
      <div class="u-margin-bottom"><Logo mini with-slogan /></div>
      <StepLayoutTitle
        :color="color"
        :title="title"
        :description="description"
      />
      <div class="u-margin-top">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import StepLayoutTitle from "./Title.vue";
import Logo from "@/components/Logo";
export default {
  components: {
    StepLayoutTitle,
    Logo,
  },
  computed: {
    getFormLoading() {
      return this.$store.state.form.isLoading;
    },
  },
  props: {
    title: {
      default: "",
    },
    description: {
      default: "",
    },
    color: {
      default: "primary",
    },
  },
};
</script>

<style></style>
