<template>
  <div>
    <AccountIsCreating v-if="getFormLoading" />
    <StepLayout
      v-else
      :title="$t('getAccountInformation.title')"
      :description="$t('getAccountInformation.description')"
    >
      <div class="row u-justify-content-center">
        <div class="es-xs-12 es-md-8 es-lg-7">
          <form @submit="HANDLE_SUBMIT_CREATE_COMPANY">
            <BaseInput
              block
              readonly
              :value="getForm.domain"
              :placeholder="$t('form.domain')"
              minlength="3"
              maxlength="15"
            >
              <template #append>
                <div class="c-form-control__append">.heocademy.com</div>
              </template>
            </BaseInput>
            <BaseInput
              block
              type="text"
              inputId="name"
              class="u-margin-top"
              @input="(e) => $store.commit('UPDATE_FORM', { name: e })"
              mandatory
              :maxlength="100"
              :value="getForm.name"
              :label="$t('form.name')"
              :placeholder="$t('form.enterName')"
            />
            <BaseInput
              class="u-margin-top"
              block
              type="password"
              :minlength="3"
              :maxlength="25"
              mandatory
              @input="(e) => $store.commit('UPDATE_FORM', { password: e })"
              :value="getForm.password"
              :label="$t('form.password')"
              :placeholder="$t('form.enterPassword')"
            />
            <div class="row">
              <div class="es-xs-4 es-md-3">
                <router-link :to="{ name: 'GetStarted' }">
                  <Button
                    variant="info"
                    block
                    class="u-margin-top-large u-color-muted"
                  >
                    <i class="icofont-arrow-left"></i>
                    {{ $t("form.back") }}
                  </Button>
                </router-link>
              </div>
              <div class="es-xs-8 es-md-9">
                <Button
                  @submit="HANDLE_SUBMIT_CREATE_COMPANY"
                  variant="primary"
                  type="submit"
                  block
                  :loading="getFormLoading"
                  class="u-margin-top-large"
                >
                  {{ $t("form.createAccount") }}
                  <i class="icofont-check"></i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StepLayout>
  </div>
</template>

<script>
import BaseInput from "@base/Inputs/BaseInput.vue";
import StepLayout from "@/layouts/StepLayout";
import Button from "@base/Button.vue";
import AccountIsCreating from "../AccountIsCreating";
export default {
  components: {
    StepLayout,
    BaseInput,
    Button,
    AccountIsCreating,
  },
  computed: {
    getForm() {
      return this.$store.state.form.form;
    },
    getFormLoading() {
      return this.$store.state.form.isLoading;
    },
  },
  methods: {
    HANDLE_SUBMIT_CREATE_COMPANY(e) {
      e.preventDefault();
      this.$store.dispatch("CREATE_COMPANY");
    },
  },
  beforeCreate() {
    const form = this.$store.state.form.form;
    if (form.domain === "") this.$router.replace({ name: "GetStarted" });
  },
  mounted() {
    const name = document.getElementById("name");
    if (name) name.focus();
  },
};
</script>

<style></style>
