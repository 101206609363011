<template>
  <div>
    <GetAccountInformation />
  </div>
</template>

<script>
import GetAccountInformation from "@/components/GetAccountInformation";
export default {
  components: {
    GetAccountInformation,
  },
};
</script>

<style></style>
